import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-41935be2"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!
  const _component_product_card = _resolveComponent("product-card")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($props.banners)
      ? (_openBlock(), _createBlock(_component_swiper, {
          key: 0,
          class: "banner-slides",
          "slides-per-view": 1,
          breakpoints: $setup.bannerOps.breakpoints,
          autoplay: $setup.bannerOps.autoplay,
          speed: $setup.bannerOps.speed,
          loop: true,
          pagination: true,
          autoHeight: false
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.banners, (banner) => {
              return (_openBlock(), _createBlock(_component_swiper_slide, { key: banner }, {
                default: _withCtx(() => [
                  _createElementVNode("img", {
                    class: "banner-img",
                    src: banner
                  }, null, 8, _hoisted_1)
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        }, 8, ["breakpoints", "autoplay", "speed"]))
      : _createCommentVNode("", true),
    ($props.featuredProducts)
      ? (_openBlock(), _createBlock(_component_swiper, {
          key: 1,
          "slides-per-view": 1,
          featuredProducts: "",
          loop: true,
          autoplay: true
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.featuredProducts, (product) => {
              return (_openBlock(), _createBlock(_component_swiper_slide, {
                key: product.id
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_product_card, {
                    product: product,
                    hideDetails: true,
                    isFeatured: true
                  }, null, 8, ["product"])
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        }))
      : ($props.products)
        ? (_openBlock(), _createBlock(_component_swiper, {
            key: 2,
            "slides-per-view": Math.min($props.products.length, $setup.ops.slidesPerView),
            breakpoints: $props.products.length >= 4 ? $setup.ops.breakpoints : null,
            autoplay: true,
            freeMode: false
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.products, (product) => {
                return (_openBlock(), _createBlock(_component_swiper_slide, {
                  key: product.id
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_product_card, {
                      product: product,
                      hideDetails: true
                    }, null, 8, ["product"])
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          }, 8, ["slides-per-view", "breakpoints"]))
        : _createCommentVNode("", true)
  ], 64))
}