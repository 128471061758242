import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_button = _resolveComponent("ion-button")!

  return (_openBlock(), _createBlock(_component_ion_button, { "router-link": "/cart" }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_icon, {
        slot: "icon-only",
        icon: $setup.cart
      }, null, 8, ["icon"]),
      ($setup.numOfCartItems > 0)
        ? (_openBlock(), _createBlock(_component_ion_badge, {
            key: 0,
            id: "notifications-badge",
            color: "danger"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($setup.numOfCartItems), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}