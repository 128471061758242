

import 'swiper/swiper-bundle.min.css';
import '@ionic/vue/css/ionic-swiper.css';

import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore, { Navigation, Pagination, Autoplay }from 'swiper';
import { IonicSwiper, IonCard, IonCardHeader, IonCardSubtitle, } from '@ionic/vue';
import ProductCard from '@/components/ProductCard.vue';

import config from '@/config';

SwiperCore.use([IonicSwiper, Navigation, Pagination, Autoplay]);

export default {
  props: [
    "products",
    "banners",
    "featuredProducts",
  ],
  components: { Swiper, SwiperSlide, ProductCard, IonCard, IonCardHeader, IonCardSubtitle, },
  setup() {
    const bannerOps = {
      speed: config.HomePage.banner.slidingSpeed,
      autoplay: {
        delay: config.HomePage.banner.autoPlayDelay,
      },
      breakpoints: {
        // when window width is >= 640px
        1024: {
          slidesPerView: config.HomePage.banner.desktopSlidesPerView,
        },
      },
    };

    const ops = {
      slidesPerView: 2,
      breakpoints: {
        // when window width is >= 640px
        768: {
          slidesPerView: 3,
        },
        1024: {
          slidesPerView: 4,
        }
      }
    };
    return {
      ops,
      bannerOps,
    }
  }
}
